import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

import Layout from '../components/Layout';
import VideoPage from '../components/VideoPage';
import { registerForReset } from '../utils/registration';
import { isRegisteredForReset } from '../utils/registration';

const Page = ({ location }) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // useEffect(() => {
  //  if (params?.inf_field_Email) {
  //     console.log(2);
  //     registerForReset();
  //     if (window) {
  //       console.log(3);
  //       navigate(window.location.pathname);
  //     }
  //   } else {
  //     console.log(4);
  //     navigate('/');
  //   }
  // }, [params, registerForReset, navigate]);

  return (
    <Layout>
      <VideoPage index={1} location={ location } />
    </Layout>
  );
};

export default Page;
